import React, { useRef, useEffect, useState, useContext } from 'react';
import { AppContext } from '@app/utils';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { randHash, useAdSlot } from '@app/utils';
import Script from 'next/script';
export const Ad = props => {
  const [mounted, setMounted] = useState(false); // Make sure that the Ad slot is always rendered at clientside, and avoid the "Prop `id` did not match" warnings.
  useEffect(() => {
    if (!mounted)
      setMounted(true)
  }, []);
  const {val2} = useContext(AppContext)
  const [aId, setAId] = val2
  const Title = props => <div className={`tw-font-body tw-text-xs tw-leading-loose tw-uppercase tw-text-center ${props.white ? 'tw-text-white' : 'tw-text-third-main'}`}>Advertisement</div>;
  const Leaderboard = props => <div className={`tw-pt-1 tw-pb-6 tw-text-center ${props.type || ''}`} >{props.children}</div>;
  const MPU = props => <div className={`tw-pt-1 tw-pb-6 tw-text-center | sm:tw-py-0 ${props.type || ''}`}>{props.children}</div>;
  const googleAd = (props.googleAd || false) && mounted;
  const hash = randHash(32);

  const AdScript = (props) => {
    const router = useRouter()
    const slot = useRef(null)
    const { adId, type } = props;
    let targetId = props.targetId || ''

    /* useEffect(() => {
      //console.log(slot.current)
      const handleRouteChange = (url, { shallow }) => {
        //console.log('handleRouteChange')
        if (window.googletag && googletag?.apiReady && slot.current) {
          //console.log('clear slot',slot.current)
          window.googletag.destroySlots([slot.current]);
        }
      }
      router.events.on('routeChangeStart', handleRouteChange)

      // If the component is unmounted, unsubscribe
      // from the event with the `off` method:
      return () => {
        router.events.off('routeChangeStart', handleRouteChange)
      }
    }, [router?.events]);
 */
    
    useEffect(() => {
      //console.log(1, window.googletag, googletag.apiReady);
      //if (window.googletag && googletag.apiReady) {
      const googletag = window.googletag || {};
      googletag.cmd = googletag.cmd || [];

      googletag.cmd.push(function () {
        googletag.pubads().collapseEmptyDivs();
        let mapping;
        switch (type) {
          case 'categoryNativeAd':
            // mapping = googletag.sizeMapping()
            //   .addSize([0, 0], ['fluid'])
            //   .build();

            slot.current = googletag.defineSlot(adId, ['fluid'], targetId)
              // .defineSizeMapping(mapping)
              .addService(googletag.pubads())
              // .setTargeting('aid',aId)
            break;
            
          case 'topLeaderboard':
            mapping = googletag.sizeMapping()
              .addSize([1024, 0], [[970, 250], [728, 90]])
              .addSize([768, 0], [728, 90])
              .addSize([0, 0], [[300, 50], [320, 100]])
              .build();

            slot.current = googletag.defineSlot(adId, [[970, 250], [728, 90], [320, 100], [300, 50]], targetId)
              .defineSizeMapping(mapping)
              .addService(googletag.pubads())
              // .setTargeting('aid',aId)
            break;

          case 'middleLeaderboard':
            mapping = googletag.sizeMapping()
              .addSize([1024, 0], [[970, 250], [728, 90]])
              .addSize([768, 0], [728, 90])
              .addSize([0, 0], [[300, 250], [300, 600]])
              .build();

            slot.current = googletag.defineSlot(adId, [[970, 250], [728, 90], [300, 250]], targetId)
              .defineSizeMapping(mapping)
              .addService(googletag.pubads())
              // .setTargeting('aid',aId);
            break;

          case 'bottomLeaderboard':
            mapping = googletag.sizeMapping()
              .addSize([768, 0], [728, 90])
              .addSize([0, 0], [[300, 50], [320, 100]])
              .build();

            slot.current = googletag.defineSlot(adId, [[728, 90], [320, 100], [300, 50]], targetId)
              .defineSizeMapping(mapping)
              .addService(googletag.pubads())
              // .setTargeting('aid',aId);
            break;

          case 'search':
            mapping = googletag.sizeMapping()
              .addSize([640, 0], [420, 150])
              .addSize([0, 0], [288, 103])
              .build();
            slot.current = googletag.defineSlot(adId, [[288, 103]], targetId)
              .defineSizeMapping(mapping)
              .addService(googletag.pubads())
              // .setTargeting('aid',aId);
            break;
          case 'prestitial':
            mapping = googletag.sizeMapping()
              .addSize([1280, 0], [[800, 600], [640, 480]])
              .addSize([768, 0], [640, 480])
              .addSize([0, 0], [300, 250])
              .build();
            slot.current = googletag.defineSlot(adId, [[800, 600], [640, 480], [300, 250]], targetId)
              .defineSizeMapping(mapping)
              .addService(googletag.pubads())
              // .setTargeting('aid',aId);
            break;
          case 'house':
          // case 'leftMpu':
          // case 'leftMpuMobile':  
            mapping = googletag.sizeMapping()
              .addSize([0, 0], [300, 250])
              .build();
            slot.current = googletag.defineSlot(adId, [[300, 250]], targetId)
              .defineSizeMapping(mapping)
              .addService(googletag.pubads())
              // .setTargeting('aid',aId);
            break;
          default:
            mapping = googletag.sizeMapping()
              .addSize([0, 0], [[300, 600], [300, 250]])
              .build();
            slot.current = googletag.defineSlot(adId, [[300, 600], [300, 250]], targetId)
              .defineSizeMapping(mapping)
              .addService(googletag.pubads())
              // .setTargeting('aid',aId);
            break;
        }
        googletag.enableServices();
      });

      googletag.cmd.push(function () {
        //console.log('ad shows',targetId);
        googletag.display(targetId);
      });
      //}


    }, [adId, aId, targetId, type, slot]);

    switch (type) {
      case 'categoryNativeAd':
        return (
          <div id={`${targetId}`} className={`${type}`}>
            {/* {targetId} */}
          </div>
        )
      default:
        return (
          <div id={`${targetId}`} className={`tw-inline-block tw--mb-1.5 ${type}`}>
            {/* {targetId} */}
          </div>
        )
    }
  }

  switch (props.type) {
    case 'categoryNativeAd':
      return googleAd && <AdScript adId={`${process.env.NEXT_PUBLIC_GOOGLE_AD_ID}/category-native-ad`} type={props.type} targetId={`gpt-ad-category-native-ad-${hash}`} />
      // return googleAd && <AdScript adId='/5450/new-asianinvestor-UAT/ctnativehome' type={props.type} targetId={`gpt-ad-category-native-ad-${hash}`} />
      // return googleAd && <AdScript adId='/5450/thecorporatetreasurer/search' type={props.type} targetId={`gpt-ad-category-native-ad-${hash}`} />
      // return googleAd && <AdScript adId='/5450/new-financeasia/nativead' type={props.type} targetId={`gpt-ad-category-native-ad-${hash}`} />

    case 'topLeaderboard':
      return <Leaderboard type={props.type} >
        <Title />
        {googleAd && <AdScript adId={`${process.env.NEXT_PUBLIC_GOOGLE_AD_ID}/top-leaderboard`} type={props.type} targetId={`gpt-ad-top-leaderboard-${hash}`} />}
      </Leaderboard>

    case 'middleLeaderboard':
      return <Leaderboard type={props.type}>
        <Title />
        {googleAd && <AdScript adId={`${process.env.NEXT_PUBLIC_GOOGLE_AD_ID}/mid-leaderboard`} type={props.type} targetId={`gpt-ad-mid-leaderboard-${hash}`} />}
      </Leaderboard>

    case 'bottomLeaderboard':
      return <Leaderboard type={props.type}>
        <Title />
        {googleAd && <AdScript adId={`${process.env.NEXT_PUBLIC_GOOGLE_AD_ID}/bottom-leaderboard`} type={props.type} targetId={`gpt-ad-bottom-leaderboard-${hash}`} />}
      </Leaderboard>

    case 'firstMpu':
      return <MPU type={props.type}>
        <Title />
        {googleAd && <AdScript adId={`${process.env.NEXT_PUBLIC_GOOGLE_AD_ID}/1st-mpu`} type={props.type} targetId={`gpt-ad-1st-mpu-${hash}`} />}
      </MPU>

    case 'secondMpu':
      return <MPU type={props.type}>
        <Title />
        {googleAd && <AdScript adId={`${process.env.NEXT_PUBLIC_GOOGLE_AD_ID}/2nd-mpu`} type={props.type} targetId={`gpt-ad-2nd-mpu-${hash}`} />}
      </MPU>

    case 'search':
      return <div className='tw-text-center'>
        {googleAd && <AdScript adId={`${process.env.NEXT_PUBLIC_GOOGLE_AD_ID}/search`} type={props.type} targetId={`gpt-ad-search-${hash}`} />}
      </div>

    case 'prestitial':
      return <div className='tw-text-center'>
        <Title white />
        {googleAd && <AdScript adId={`${process.env.NEXT_PUBLIC_GOOGLE_AD_ID}/prestitial`} type={props.type} targetId={`gpt-ad-prestitial-${hash}`} />}
      </div>

    case 'house':
      return <MPU type={props.type}>
        <Title />
        {googleAd && <AdScript adId={`${process.env.NEXT_PUBLIC_GOOGLE_AD_ID}/house`} type={props.type} targetId={`gpt-ad-house-${hash}`} />}
      </MPU>

    case 'leftMpu':
      return <MPU type={props.type}>
        <Title />
        {googleAd && <AdScript adId={`${process.env.NEXT_PUBLIC_GOOGLE_AD_ID}/leftmpu`} type={props.type} targetId={`gpt-ad-left-mpu-${hash}`} />}
      </MPU>

    case 'leftMpuMobile':
      return <MPU type={props.type}>
        <Title />
        {googleAd && <AdScript adId={`${process.env.NEXT_PUBLIC_GOOGLE_AD_ID}/leftmpumobile`} type={props.type} targetId={`gpt-ad-left-mpu-mobile-${hash}`} />}
      </MPU>

    default:
      return null;
  }
}

export default Ad;

Ad.propTypes = {
  /**
  * Type of ad name
  */
  type: PropTypes.string.isRequired,
  /**
  * Google ad get
  */
  googleAd: PropTypes.bool,
}
