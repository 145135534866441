import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Ad, Button } from '@app/components';
import JsonProps from '@app/header.json';
import JsonPropsUAT from '@app/header-uat.json';

const JsonDataContent = process.env.NEXT_PUBLIC_APP_ENVIRONMENT === 'Production' ? JsonProps : JsonPropsUAT;

const Counter = props => {
  const [seconds, setSeconds] = useState(props.counter);

  useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
    } else {
      setSeconds(0);
      props.onClick();
    }
  }, [seconds, props]);

  // return <Button title={`This Ad will end in ${seconds} sec. Please click here to skip.`} btnType='box' onClick={props.onClick} classes='tw-w-72' />
  return <Button title='close' btnType='boxIcon' size='lg' onClick={props.onClick} />
}

export const Prestitial = props => {
  const { logo } = JsonDataContent;
  const [render, setRender] = useState(false);

  const handleClose = () => {
    setRender(false);
  }

  useEffect(() => {
    // Add localStrage
    const prestad = localStorage.getItem('prestad');
    const date = Date.now();
    
    if (!prestad || prestad < date) {
      
      localStorage.setItem('prestad', date + (props.hours * 60 * 60 * 1000));
      setRender(true);
    }
    
    // Callback display popup window
    googletag.cmd.push(function () {
      googletag.pubads().addEventListener('slotRenderEnded', function (event) {
        if (document.querySelector(`[id*='${process.env.NEXT_PUBLIC_GOOGLE_AD_ID}/prestitial']`)?.childNodes.length > 0) {
          document.getElementById('prestitialPopup').style.display = 'block';
        }
      })
    })
  }, [props.hours])

  return render &&
    <div id='prestitialPopup' className='tw-hidden tw-fixed tw-top-0 tw-left-0 tw-z-[2147483647] tw-w-full tw-h-full tw-bg-opacity-[0.9] tw-bg-black tw-pt-8'>
      <img src={logo.logo} alt={logo.alt} className='tw-w-32 tw-mx-auto tw-mb-4 | sm:tw-w-64' />
      <div className='tw-fixed tw-top-0 tw-right-0'><Counter onClick={handleClose} counter={props.counter} /></div>
      <Ad googleAd type='prestitial' />
      {/* <div className='tw-text-center tw-mt-4'><Counter onClick={handleClose} counter={props.counter} /></div> */}
    </div>
}

export default Prestitial;

Prestitial.propTypes = {
  /**
  * How long repeat the ad, unit by hours
  */
  hours: PropTypes.number,
  /**
  * How long to skip, unit by second
  */
  counter: PropTypes.number,
}

Prestitial.defaultProps = {
  hours: 4,
  counter: 20,
}