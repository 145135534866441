import React from 'react';
import Link from 'next/link';
import PropTypes from 'prop-types';
import { linkHelper } from '@app/utils';

/**
* Button UI component
*/
export const Button = ({ id, slug, type, title, url, noRoute, target, btnType, hidden, onClick, active, long, size, color, outline, noResponsive, noEffect, classes, subNav, ...props }) => {
  // For nav, xsNav use active style or hover style
  const activeClass = active ? 'tw-bg-secondary-main' : '| hover:tw-bg-primary-main';
  // Color for text', 'box', 'icon', 'boxIcon'
  const colorClass = color === 'primary' && (btnType === 'text' || btnType === 'icon') ? `tw-text-primary-main  ${!noEffect ? '| hover:tw-text-primary-dark' : ''}`
    : color === 'primary' && !outline && (btnType === 'box' || btnType === 'boxIcon') ? `tw-text-white tw-bg-primary-main ${!noEffect ? '| hover:tw-bg-primary-dark' : ''}`
      : color === 'primary' && outline && (btnType === 'box' || btnType === 'boxIcon') ? `tw-text-primary-main tw-bg-white tw-border-solid tw-border-primary-main tw-border ${!noEffect ? '| hover:tw-text-white hover:tw-bg-primary-main hover:tw-border-white' : ''}`
        : color === 'secondary' && (btnType === 'text' || btnType === 'icon') ? `tw-text-secondary-main ${!noEffect ? '| hover:tw-text-primary-main' : ''}`
          : color === 'secondary' && !outline && (btnType === 'box' || btnType === 'boxIcon') ? `tw-text-white tw-bg-secondary-main ${!noEffect ? '| hover:tw-bg-secondary-dark' : ''}`
            : color === 'secondary' && outline && (btnType === 'box' || btnType === 'boxIcon') ? `tw-text-secondary-main tw-bg-white tw-border-solid tw-border-secondary-main tw-border ${!noEffect ? '| hover:tw-text-white hover:tw-bg-secondary-main hover:tw-border-white' : ''}`
              : color === 'transparent' && !outline && (btnType === 'box' || btnType === 'boxIcon') ? `tw-text-primary-main ${!noEffect ? '| hover:tw-text-white hover:tw-bg-primary-main' : ''}`
                : color === 'transparent' && outline && (btnType === 'box' || btnType === 'boxIcon') ? `tw-text-primary-main tw-border-solid tw-border-primary-main tw-border ${!noEffect ? '| hover:tw-text-white hover:tw-bg-primary-main hover:tw-border-white' : ''}`
                  : color === 'white' && (btnType === 'text' || btnType === 'icon') ? `tw-text-white ${!noEffect ? '| hover:tw-text-primary-main' : ''}`
                    : color === 'gray' && (btnType === 'text' || btnType === 'icon') ? `tw-text-gray-400 ${!noEffect ? '| hover:tw-text-gray-500' : ''}`
                      : '';
  const hiddenClass = hidden ? '| ' + hidden + ':tw-hidden ' : '';
  const textClass = 'tw-inline-block tw-transition-all tw-text-sm tw-font-extrabold tw-cursor-pointer'
    + ' ' + colorClass
    + ' ' + (noResponsive ?
      (size => {
        switch (size) {
          case '2xl':
            return 'tw-p-1em tw-text-2xl tw-leading-box-2xl';
            break;
          case 'xl':
            return 'tw-p-1em tw-text-xl tw-leading-box-xl';
            break;
          case 'lg':
            return 'tw-p-1em tw-text-lg tw-leading-box-lg';
            break;
          case 'md':
            return 'tw-p-1em tw-text-base tw-leading-box-md';
            break;
          case 'sm':
            return 'tw-p-1em tw-text-sm tw-leading-box-sm';
            break;
          case 'xs':
            return 'tw-p-1em tw-text-xs tw-leading-box-xs';
            break;
          default:
            return '';
            break;
        }
      })(size)
      : (size => {
        switch (size) {
          case '2xl':
            return 'tw-p-1em tw-text-lg tw-leading-box-lg | sm:tw-text-xl sm:tw-leading-box-xl | lg:tw-text-2xl lg:tw-leading-box-2xl';
            break;
          case 'xl':
            return 'tw-p-1em tw-text-base tw-leading-box-md | sm:tw-text-lg sm:tw-leading-box-lg | lg:tw-text-xl lg:tw-leading-box-xl';
            break;
          case 'lg':
            return 'tw-p-1em tw-text-sm tw-leading-box-sm | sm:tw-text-base sm:tw-leading-box-md | lg:tw-text-lg lg:tw-leading-box-lg';
            break;
          case 'md':
            return 'tw-p-1em tw-text-xs tw-leading-box-xs | sm:tw-text-sm sm:tw-leading-box-sm | lg:tw-text-base lg:tw-leading-box-md';
            break;
          case 'sm':
            return 'tw-p-box-xs-sm tw-text-xs tw-leading-box-xs | sm:tw-p-1em | lg:tw-text-sm lg:tw-leading-box-sm';
            break;
          case 'xs':
            return 'tw-p-box-xs-xs tw-text-xs tw-leading-box-xs | sm:tw-p-box-xs-sm | lg:tw-p-1em';
            break;
          default:
            return '';
            break;
        }
      })(size)
    )
    + ' ' + hiddenClass;
  const boxClass = 'tw-inline-block tw-transition-all tw-font-headline tw-font-extrabold tw-uppercase tw-text-center tw-cursor-pointer'
    + ' ' + colorClass
    + ' ' + (long ? 'tw-min-w-long'
      : size === 'sm' ? 'tw-min-w-10em tw-max-w-full | sm:tw-min-w-12em'
        : size === 'xs' ? 'tw-min-w-8em tw-max-w-full | sm:tw-min-w-10em | lg:tw-min-w-12em'
          : 'tw-min-w-12em tw-max-w-full')
    + ' ' + (noResponsive ?
      (size => {
        switch (size) {
          case '2xl':
            return 'tw-p-1em tw-text-2xl tw-leading-box-2xl';
            break;
          case 'xl':
            return 'tw-p-1em tw-text-xl tw-leading-box-xl';
            break;
          case 'lg':
            return 'tw-p-1em tw-text-lg tw-leading-box-lg';
            break;
          case 'md':
            return 'tw-p-1em tw-text-base tw-leading-box-md';
            break;
          case 'sm':
            return 'tw-p-1em tw-text-sm tw-leading-box-sm';
            break;
          case 'xs':
            return 'tw-p-1em tw-text-xs tw-leading-box-xs';
            break;
          default:
            return '';
            break;
        }
      })(size)
      : (size => {
        switch (size) {
          case '2xl':
            return 'tw-p-1em tw-text-lg tw-leading-box-lg | sm:tw-text-xl sm:tw-leading-box-xl | lg:tw-text-2xl lg:tw-leading-box-2xl';
            break;
          case 'xl':
            return 'tw-p-1em tw-text-base tw-leading-box-md | sm:tw-text-lg sm:tw-leading-box-lg | lg:tw-text-xl lg:tw-leading-box-xl';
            break;
          case 'lg':
            return 'tw-p-1em tw-text-sm tw-leading-box-sm | sm:tw-text-base sm:tw-leading-box-md | lg:tw-text-lg lg:tw-leading-box-lg';
            break;
          case 'md':
            return 'tw-p-1em tw-text-xs tw-leading-box-xs | sm:tw-text-sm sm:tw-leading-box-sm | lg:tw-text-base lg:tw-leading-box-md';
            break;
          case 'sm':
            return 'tw-p-box-xs-sm tw-text-xs tw-leading-box-xs | sm:tw-p-1em | lg:tw-text-sm lg:tw-leading-box-sm';
            break;
          case 'xs':
            return 'tw-p-box-xs-xs tw-text-xs tw-leading-box-xs | sm:tw-p-box-xs-sm | lg:tw-p-1em';
            break;
          default:
            return '';
            break;
        }
      })(size)
    )
    + ' ' + hiddenClass;
  const iconClass = 'tw-block tw-transition-all tw-font-icon tw-text-center tw-cursor-pointer'
    + ' ' + colorClass
    + ' ' + (noResponsive ?
      (size => {
        switch (size) {
          case '2xl':
            return 'tw-text-5xl tw-leading-none';
            break;
          case 'xl':
            return 'tw-text-4xl tw-leading-none';
            break;
          case 'lg':
            return 'tw-text-3xl tw-leading-none';
            break;
          case 'md':
            return 'tw-text-2xl tw-leading-none';
            break;
          case 'sm':
            return 'tw-text-xl tw-leading-none';
            break;
          case 'xs':
            return 'tw-text-lg tw-leading-none';
            break;
          default:
            return '';
            break;
        }
      })(size)
      : (size => {
        switch (size) {
          case '2xl':
            return 'tw-text-3xl tw-leading-none | sm:tw-text-4xl sm:tw-leading-none | lg:tw-text-5xl lg:tw-leading-none';
            break;
          case 'xl':
            return 'tw-text-2xl tw-leading-none | sm:tw-text-3xl sm:tw-leading-none | lg:tw-text-4xl lg:tw-leading-none';
            break;
          case 'lg':
            return 'tw-text-xl tw-leading-none | sm:tw-text-2xl sm:tw-leading-none | lg:tw-text-3xl lg:tw-leading-none';
            break;
          case 'md':
            return 'tw-text-lg tw-leading-none | sm:tw-text-xl sm:tw-leading-none | lg:tw-text-2xl lg:tw-leading-none';
            break;
          case 'sm':
            return 'tw-text-base tw-leading-none | sm:tw-text-lg sm:tw-leading-none | lg:tw-text-xl lg:tw-leading-none';
            break;
          case 'xs':
            return 'tw-text-sm tw-leading-none | sm:tw-text-base sm:tw-leading-none | lg:tw-text-lg lg:tw-leading-none';
            break;
          default:
            return '';
            break;
        }
      })(size)
    )
    + ' ' + hiddenClass;
  const boxIconClass = 'tw-block tw-transition-all tw-font-icon tw-text-center tw-cursor-pointer'
    + ' ' + colorClass
    + ' ' + (noResponsive ?
      (size => {
        switch (size) {
          case '2xl':
            return 'tw-text-5xl tw-leading-none tw-h-19 tw-w-19';
            break;
          case 'xl':
            return 'tw-text-4xl tw-leading-none tw-h-16 tw-w-16';
            break;
          case 'lg':
            return 'tw-text-3xl tw-leading-none tw-h-14.5 tw-w-14.5';
            break;
          case 'md':
            return 'tw-text-2xl tw-leading-none tw-h-13 tw-w-13';
            break;
          case 'sm':
            return 'tw-text-xl tw-leading-none tw-h-12 tw-w-12';
            break;
          case 'xs':
            return 'tw-text-lg tw-leading-none tw-h-10 tw-w-10';
            break;
          default:
            return '';
            break;
        }
      })(size)
      : (size => {
        switch (size) {
          case '2xl':
            return 'tw-text-3xl tw-leading-none tw-h-14.5 tw-w-14.5 | sm:tw-text-4xl sm:tw-leading-none sm:tw-h-16 sm:tw-w-16 | lg:tw-text-5xl lg:tw-leading-none lg:tw-h-19 lg:tw-w-19';
            break;
          case 'xl':
            return 'tw-text-2xl tw-leading-none tw-h-13 tw-w-13 | sm:tw-text-3xl sm:tw-leading-none sm:tw-h-14.5 sm:tw-w-14.5 | lg:tw-text-4xl lg:tw-leading-none lg:tw-h-16 lg:tw-w-16';
            break;
          case 'lg':
            return 'tw-text-xl tw-leading-none tw-h-12 tw-w-12 | sm:tw-text-2xl sm:tw-leading-none sm:tw-h-13 sm:tw-w-13 | lg:tw-text-3xl lg:tw-leading-none lg:tw-h-14.5 lg:tw-w-14.5';
            break;
          case 'md':
            return 'tw-text-lg tw-leading-none tw-h-10 tw-w-10 | sm:tw-text-xl sm:tw-leading-none sm:tw-h-12 sm:tw-w-12 | lg:tw-text-2xl lg:tw-leading-none lg:tw-h-13 lg:tw-w-13';
            break;
          case 'sm':
            return 'tw-text-base tw-leading-none tw-h-9 tw-w-9 | sm:tw-text-lg sm:tw-leading-none sm:tw-h-10 sm:tw-w-10 | lg:tw-text-xl lg:tw-leading-none lg:tw-h-12 lg:tw-w-12';
            break;
          case 'xs':
            return 'tw-text-sm tw-leading-none tw-h-8 tw-w-8 | sm:tw-text-base sm:tw-leading-none sm:tw-h-9 sm:tw-w-9 | lg:tw-text-lg lg:tw-leading-none lg:tw-h-10 lg:tw-w-10';
            break;
          default:
            return '';
            break;
        }
      })(size)
    )
    + ' ' + hiddenClass;
  const navClass = `tw-block tw-text-sm tw-font-extrabold tw-tracking-tighter tw-uppercase tw-text-center tw-text-white tw-h-16 tw-px-3 tw-transition-all ${activeClass} | 2xl:tw-px-4`;
  const xsNavClass = `tw-block tw-text-base tw-font-extrabold tw-tracking-tighter tw-uppercase tw-text-white tw-text-left tw-px-2 tw-py-2 tw-border-b tw-border-gray-700 tw-transition-all ${activeClass} | sm:tw-px-4 | 2xl:tw-px-4`;
  const subNavClass = 'tw-block tw-text-sm tw-leading-7 tw-font-extrabold tw-tracking-tighter tw-whitespace-nowrap tw-uppercase tw-text-white tw-h-12 tw-px-3 tw-transition-all | hover:tw-bg-secondary-dark | xl:tw-text-base xl:tw-h-16 | 2xl:tw-px-4';
  const navIconClass = `tw-block tw-transition-all tw-font-icon tw-text-2xl tw-text-center tw-text-white tw-h-12 tw-px-2 tw-cursor-pointer | hover:tw-text-primary-main | xl:tw-text-3xl xl:tw-px-2 xl:tw-h-16 ${hiddenClass}`;
  const subscribeClass = `tw-block tw-transition-all tw-text-xs tw-tracking-tighter tw-font-extrabold tw-text-center tw-text-white tw-bg-primary-main tw-h-12 tw-px-2 tw-cursor-pointer | hover:tw-bg-primary-dark | xl:tw-text-sm xl:tw-h-16 xl:tw-px-4 ${hiddenClass}`;
  const className = (btnType === 'text' && textClass)
    || (btnType === 'box' && boxClass)
    || (btnType === 'icon' && iconClass)
    || (btnType === 'boxIcon' && boxIconClass)
    || (btnType === 'navIcon' && navIconClass)
    || (btnType === 'subscribe' && subscribeClass)
    || (btnType === 'nav' && navClass)
    || (btnType === 'xsNav' && xsNavClass)
    || (btnType === 'subNav' && subNavClass);
  const subClass = `tw-block tw-relative tw-top-1/2 tw-transform tw--translate-y-1/2`;
  const subClassName = ((btnType === 'boxIcon' || btnType === 'navIcon' || btnType === 'nav' || btnType === 'subNav' || btnType === 'subscribe') && subClass) || undefined;

  return (
    url || type || slug ? noRoute ?
      <a id={id} href={url || linkHelper(type, slug, id)} className={className + (classes ? ' ' + classes : '')} target={target} {...props}><span className={subClassName}>{title}</span></a>
      // <button href={url || linkHelper(type, slug, id)} className={className + (classes ? ' ' + classes : '')} target={target} {...props}>{title}</button>
      : 
      <Link href={url || linkHelper(type, slug, id)} passHref>
        <a id={id} className={className + (classes ? ' ' + classes : '')} target={target} {...props}><span className={subClassName}>{title}</span></a>
        {/* <button className={className + (classes ? ' ' + classes : '')} target={target} {...props}>{title}</button> */}
      </Link>
      // <a href={url || linkHelper(type, slug, id)} className={className + (classes ? ' ' + classes : '')} target={target} {...props}><span className={subClassName}>{title}</span></a>
      // <button href={url || linkHelper(type, slug, id)} className={className + (classes ? ' ' + classes : '')} target={target} {...props}>{title}</button>
      : <button id={id} className={className + (classes ? ' ' + classes : '')} alt={title} onClick={onClick || undefined} {...props}>{title}</button>
  )
};

export default Button;

Button.propTypes = {
  /**
  * Identify button
  */
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  /**
  * Slug of the button for hyperlink
  */
  slug: PropTypes.string,
  /**
  * Type of the button for hyperlink
  */
  type: PropTypes.string,
  /**
  * Title or icon text of the button
  */
  title: PropTypes.string.isRequired,
  /**
  * Hyperlink of the button
  */
  url: PropTypes.string,
  /**
  * No routing option, for everytime need to refresh
  */
  noRoute: PropTypes.string,
  /**
  * Target of hyperlink
  */
  target: PropTypes.string,
  /**
  * Type of the button style, 'text' for text only, 'box' for button style, 'icon' for icon only, 'icon' for icon button, 'nav' for big size screen's navigation button, 'xsNav' for small size screen's navigation button, 'subNav' for sub navigation button, 'subscribe' for subscribe button
  */
  btnType: PropTypes.oneOf(['text', 'box', 'icon', 'boxIcon', 'nav', 'xsNav', 'subNav', 'subscribe', 'navIcon']),
  /**
  * Which screen size to hide the button, follow breakpoint size ('sm', 'md', 'lg', 'xl', '2xl')
  */
  hidden: PropTypes.oneOf(['sm', 'md', 'lg', 'xl', '2xl']),
  /**
  * Call back function of the button
  */
  onClick: PropTypes.func,
  /**
  * Active button
  */
  active: PropTypes.bool,
  /**
  * Is Box button is long style button?
  */
  long: PropTypes.bool,
  /**
  * Size for button ('xs', 'sm', 'md', 'lg', 'xl', '2xl')
  */
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', '2xl']),
  /**
  * Color for button ('primary', 'secondary', 'transparent', 'white', 'black', 'gray', 'success', 'error', 'info', 'warning')
  */
  color: PropTypes.oneOf(['primary', 'secondary', 'transparent', 'white', 'black', 'gray', 'success', 'error', 'info', 'warning']),
  /**
  * Ouline format of button, only use on btnType 'text', 'box', 'icon', 'boxIcon'
  */
  outline: PropTypes.bool,
  /**
  * Control no responsive for button, only use on btnType 'text', 'box', 'icon', 'boxIcon'
  */
  noResponsive: PropTypes.bool,
  /**
  * Control no hover effect for button
  */
  noEffect: PropTypes.bool,
  /**
  * Extra classes for button
  */
  classes: PropTypes.string,
  /**
  * Array of Sub navigation button (if it is subNav)
  */
  subNav: PropTypes.array,
}

Button.defaultProps = {
  active: false,
  btnType: 'text',
  color: 'primary',
  long: false,
  size: 'md',
  classes: '',
  noResponsive: false,
  noEffect: false,
}