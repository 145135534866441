import React from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { Logo, Button } from '@app/components';
import DefaultProps from '@app/header.json';
import DefaultPropsUAT from '@app/header-uat.json';
import metadata from '@app/metadata.json';

const DefaultPropsContent = process.env.NEXT_PUBLIC_APP_ENVIRONMENT === 'Production' ? DefaultProps : DefaultPropsUAT;

/**
* Navigation UI component
*/
export const Navigation = props => {
  const router = useRouter();
  const query = router?.query || props.router?.query || {};
  const asPath = router?.asPath || props.router?.asPath || '';
  const { categories, countries } = metadata;

  const findObject = (objs, slug) => objs[objs.map(o => !o.hideInHeader && o.slug?.toLowerCase()).indexOf(slug)] || null;

  const checkType = objs =>
    objs.some(o => o.type === asPath.substring(1) && !o.hideInHeader);

  const checkCategory = slug =>
    slug === query.category?.toLowerCase() ? true : false;

  const checkId = id =>
    id === Number(query.id) ? true : false;

  const checkCategoryBase = (slug, id) =>
    checkId(id) && checkCategory(slug) ? true : false;

  const checkCategorySubButton = buttons =>
    buttons ?
      buttons.some(subButton => subButton === query.category)
      : false;

  const checkCountryBase = (slug, id) =>
    checkId(id) && slug ? true : false;

  const checkCountrySubButton = buttons =>
    buttons ?
      buttons.some(subButton => subButton === query.country)
      : false;

  const findParentButtton = () => {
    let index;
    jsonCategories.subNav.map((button, i) => {
      if (jsonCategories.subNav[i].subNav?.some(subButton => subButton === query.category)) {
        index = i
      }
    })
    return index;
  }

  const jsonCategories = findObject(props.nav, 'categories');
  const jsonEvents = findObject(props.nav, 'events');
  const jsonOthers = findObject(props.nav, 'others');
  const jsonOurSites = findObject(props.nav, 'our-sites');
  const jsonCountries = findObject(props.nav, 'countries');

  // Other preset link
  const otherButton = jsonOthers && {
    ...findObject(categories, 'awards'),
    type: 'category',
    // type: 'event',
    // display: jsonOthers?.shortDisplay,
  };

  // Country preset link
  const countryButton = findObject(countries, 'world');

  switch (props.btnType) {
    case 'nav':
      return (
        <nav className='tw-hidden | xl:tw-inline-flex'>
          {jsonCategories &&
            jsonCategories.subNav.map((button, i) => {
              const buttonData = findObject(categories, button.slug);
              return buttonData ?
                (({ display, ...props }) =>
                  <Button key={i} title={button.display || display} type='category' btnType='nav' {...props} active={checkCategoryBase(props.slug, props.id) || checkCategorySubButton(button.subNav) ? true : false} />)(buttonData)
                : ''
            })}

          {jsonEvents &&
            jsonEvents ? (({ display, ...props }) =>
              <Button key='events' title={display} btnType='nav' {...props} />)(jsonEvents)
            : ''
          }

          {jsonOthers &&
            otherButton ? (({ display, ...props }) =>
              <Button key='others' title={display} btnType='nav' {...props} active={(query.category && query.id && findObject(jsonOthers.subNav, query.category)) || checkType(jsonOthers.subNav) ? true : false} />)(otherButton)
            : ''
          }

          {jsonCountries &&
            countryButton ? (({ display, ...props }) =>
              <Button key='countries' title={jsonCountries.display} type='country' btnType='nav' {...props} active={checkCountryBase(query.country, props.id) || checkCountrySubButton(jsonCountries.subNav) ? true : false} />)(countryButton)
            : ''
          }

          {jsonOurSites &&
            jsonOurSites.subNav.map((button, i) => !button.hideInHeader ? (({ shortDisplay, display, ...props }) =>
              <Button key='ourSites' title={display} btnType='nav' {...props} />)(button)
              : ''
            )}
        </nav>
      )
      break;
    case 'xsNav':
      return (
        <div className={`tw-transition-all tw-fixed tw-z-[300] tw-top-0 tw-left-0 tw-w-screen tw-px-8 tw-pt-4 tw-pb-8 tw-overflow-y-auto tw-bg-black ${props.open ? 'tw-visible tw-opacity-100 tw-h-screen' : 'tw-invisible tw-opacity-0 tw-h-1/2'} | xl:tw-hidden`}>
          <div className='tw-flex'>
            <Logo />
            <div className='tw-flex-grow' />
            <Button title='close' btnType='boxIcon' color='transparent' onClick={props.onClose} />
          </div>
          <nav className='tw-grid tw-grid-cols-1' onClick={props.onClose}>
            {jsonCategories &&
              jsonCategories.subNav.map((button, i) => {
                const buttonData = findObject(categories, button.slug);
                return buttonData ?
                  (({ display, ...props }) =>
                    <Button key={i} title={button.display || display} type='category' btnType='xsNav' {...props} active={checkCategoryBase(props.slug, props.id) || checkCategorySubButton(button.subNav) ? true : false} />)(buttonData)
                  : ''
              })}

            {jsonEvents &&
              jsonEvents ? (({ display, ...props }) =>
                <Button key='events' title={display} btnType='xsNav' {...props} />)(jsonEvents)
              : ''
            }
            
            {jsonOthers &&
              otherButton ? (({ display, ...props }) =>
                <Button key='others' title={display} btnType='xsNav' {...props} active={(query.category && query.id && findObject(jsonOthers.subNav, query.category)) || checkType(jsonOthers.subNav) ? true : false} />)(otherButton)
              : ''
            }

            {jsonCountries &&
              countryButton ? (({ display, ...props }) =>
                <Button key='countries' title={jsonCountries.display} type='country' btnType='xsNav' {...props} active={checkCountryBase(query.country, props.id) || checkCountrySubButton(jsonCountries.subNav) ? true : false} />)(countryButton)
              : ''
            }

            {jsonOurSites &&
              jsonOurSites.subNav.map((button, i) => !button.hideInHeader ? (({ shortDisplay, display, ...props }) =>
                <Button key='ourSites' title={display} btnType='xsNav' {...props} />)(button)
                : ''
              )}

            {/* {props.nav.map((button, i) => <Button key={i} {...button} activeId={props.activeId} btnType={props.btnType} />)} */}
          </nav>
          {props.open &&
            <style>{"\
              body {\
                height: 100vh;\
                overflow: hidden;\
              }\
            "}</style>
          }
        </div>
      )
      break;
    case 'subNav':
      return (
        <nav className='tw-bg-secondary-main tw-text-center tw-overflow-x-auto tw-overflow-y-hidden'>
          <div className='tw-inline-flex tw-justify-start | empty:tw-hidden'>
            {query.category && query.id && findObject(jsonCategories.subNav, query.category) && findObject(jsonCategories.subNav, query.category).subNav &&
              findObject(jsonCategories.subNav, query.category).subNav.map((button, i) => {
                const buttonData = findObject(categories, button.slug);
                return !button.hideInHeader ? buttonData ?
                  (({ display, ...props }) =>
                    <Button key={i} title={button.display || display} type='category' {...props} btnType='subNav' />)(buttonData)
                  : (({ display, ...props }) =>
                    <Button key={i} title={display} {...props} btnType='subNav' />)(button)
                  : ''
              })}

            {findParentButtton() > -1 &&
              jsonCategories.subNav[findParentButtton()].subNav.map((button, i) => {
                const buttonData = findObject(categories, button.slug);
                return !button.hideInHeader ? buttonData ?
                  (({ display, ...props }) =>
                    <Button key={i} title={button.display || display} type='category' {...props} btnType='subNav' />)(buttonData)
                  : (({ display, ...props }) =>
                    <Button key={i} title={display} {...props} btnType='subNav' />)(button)
                  : ''
              })}

            
            {jsonOthers && ((query.category && query.id && findObject(jsonOthers.subNav, query.category)) || checkType(jsonOthers.subNav)) &&
              jsonOthers.subNav.map((button, i) => {
                const buttonData = findObject(categories, button.slug);
                return !button.hideInHeader ? buttonData ?
                  (({ display, ...props }) =>
                    <Button key={i} title={button.display || display} type='category' {...props} btnType='subNav' />)(buttonData)
                  : (({ display, ...props }) =>
                    <Button key={i} title={display} {...props} btnType='subNav' />)(button)
                  : ''
              })}

            {query.country && query.id &&
              jsonCountries?.subNav.map((button, i) => {
                const buttonData = findObject(countries, button);
                return !button.hideInHeader ? buttonData &&
                  (({ display, ...props }) =>
                    <Button key={i} title={display} type='country' {...props} btnType='subNav' />)(buttonData)
                  : ''
              })}
            {/* {props.nav.map((button, i) =>
              ((button.id === props.activeId && button.subNav) || (button.subNav && button.subNav.some(subButton => subButton.id === props.activeId))) &&
              button.subNav.map((subButton, index) =>
                <Button key={index} {...subButton} btnType={props.btnType} />
              )
            )} */}
          </div>
        </nav>
      )
      break;
    default:
      console.error('Navigator component error, suppose btnType is one of "nav", "xsNav", "subNav"!!')
      return null
      break;
  }
}

export default Navigation;

Navigation.propTypes = {
  /**
  * List of navigation buttons data
  */
  nav: PropTypes.array,
  /**
  * Type of the button style, 'nav' for big size screen's navigation button, 'xsNav' for small size screen's navigation button, 'subNav' for sub navigation button
  */
  btnType: PropTypes.oneOf(['nav', 'xsNav', 'subNav']),
  /**
  * Menu open (only for small menu)
  */
  open: PropTypes.bool,
  /**
  * onClose call back function (only for small menu)
  */
  onClose: PropTypes.func
}

Navigation.defaultProps = {
  nav: DefaultPropsContent.navigation,
  btnType: 'nav',
  open: false,
  onClose: undefined
}